import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Whether you’re designing or building, testing for accessibility is important because you need to make sure your service meets level AA of the Web Content Accessibility Guidelines 2.2 (WCAG) as a minimum.`}</p>
    <p>{`To meet these guidelines, you should consider accessibility at the start of your project and run accessibility tests throughout.`}</p>
    <p>{`This guidance explains the different types of accessibility testing you should be using with your projects.`}</p>
    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#types-of-accessibility-testing"
            }}>{`Types of accessibility testing`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#automated-testing"
            }}>{`Automated testing`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#user-testing"
            }}>{`User testing`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#manual-testing"
            }}>{`Manual testing`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <h2 {...{
      "id": "types-of-accessibility-testing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#types-of-accessibility-testing",
        "aria-label": "types of accessibility testing permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Types of accessibility testing`}</h2>
    <p>{`It's important to test accessibility regularly, using a mixture of all these types of testing:`}</p>
    <ul>
      <li parentName="ul">{`Automated testing with browser plugins and test automation frameworks for CI/CD integration`}</li>
      <li parentName="ul">{`Manual testing to measure website and page compliance against WCAG standards`}</li>
      <li parentName="ul">{`User testing with users who have disabilities or users of assistive technologies to understand the usability of a journey or task.`}</li>
    </ul>
    <p>{`If you just focus on automated testing, you'll likely miss some accessibility issues with your code or design.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "automated-testing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#automated-testing",
        "aria-label": "automated testing permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Automated testing`}</h2>
    <p>{`Automated testing tools can audit your product against the Web Content Accessibility Guidelines (WCAG) to make sure you’re always following best practices that can be detected. Automated testing is estimated to detect approximately 20% to 40% of common WCAG issues.`}</p>
    <p>{`Integrating accessibility testing into your products also gives your team more time to focus on the more complex accessibility tests that need to be completed manually.`}</p>
    <p>{`There are a range of tools for automated testing including:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/dequelabs/axe-core"
        }}>{`Deque’s axe-core for the web`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/google/GTXiLib"
        }}>{`Google’s Toolbox for Accessibility for iOS (GTXiLib)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/google/Accessibility-Test-Framework-for-Android"
        }}>{`Google’s Accessibility Test Framework for Android (ATF)`}</a></li>
    </ul>
    <p>{`If you need help implementing your chosen automated testing tool into your CI/CD pipeline, here are a few implementation examples:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/sainsburys-tech/dig-navigation-ui-search/blob/master/test/docs/TESTING.md"
        }}>{`Sainsbury’s Tech web navigation team`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://engineering.linkedin.com/blog/2020/automated-accessibility-testing"
        }}>{`LinkedIn’s approach to test automation`}</a></li>
    </ul>
    <h3>{`Browser plugins`}</h3>
    <p>{`Browser plugins provide an alternative to implementing automated testing tools into the CI/CD by allowing the user to manually run a set of checks within the browser.`}</p>
    <p>{`Most plugins provide both fully automated checks and semi-automated or ‘review’ checks that flag potential issues to be assessed by a human user.`}</p>
    <p>{`We recommend the following the two browser plugins to get started:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/axe-devtools-web-accessib/lhdoppojpmngadmnindnejefpokejbdd"
        }}>{`axe DevTools - Web Accessibility Testing`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/accessibility-insights-fo/pbjjkligggfmakdaogkfomddhfmpjeni?hl=en"
        }}>{`Accessibility Insights for Web`}</a></li>
    </ul>
    <hr></hr>
    <h2 {...{
      "id": "user-testing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#user-testing",
        "aria-label": "user testing permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`User testing`}</h2>
    <p>{`We strongly recommend testing with users wherever possible.`}</p>
    <p>{`User testing will help you understand real-world accessibility issues, such as how people with disabilities or people who use assistive technologies understand your product.`}</p>
    <p>{`User testing will usually involve:`}</p>
    <ul>
      <li parentName="ul">{`getting users with disabilities or who use assistive technologies to complete a journey or task`}</li>
      <li parentName="ul">{`watching your users navigate and complete tasks so you can see how different aspects of the design or code could be improved`}</li>
      <li parentName="ul">{`discussing and reviewing the accessibility issues your users encountered`}</li>
    </ul>
    <p>{`If you're a Sainsbury's colleague and need to conduct user testing, please contact a consultant using our get in touch section.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "manual-testing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#manual-testing",
        "aria-label": "manual testing permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Manual testing`}</h2>
    <p>{`To get started with manual accessibility testing you can refer to the `}<a parentName="p" {...{
        "href": "https://luna.sainsburys.co.uk/accessibility/accessibility-standards-for-web/"
      }}>{`accessibility standards for web`}</a>{` to help you test for common accessibility barriers, including:`}</p>
    <ul>
      <li parentName="ul">{`lack of colour contrast for text and controls`}</li>
      <li parentName="ul">{`lack of keyboard accessibility`}</li>
      <li parentName="ul">{`interactive elements that aren’t descriptive`}</li>
      <li parentName="ul">{`images and elements not having meaningful alt text.`}</li>
    </ul>
    <p>{`We recommend that you integrate these accessibility principles into your QA process.`}</p>
    <p>{`The accessibility principles are not an exhaustive list of accessibility tests but do provide a good foundation for building accessible products.`}</p>
    <h3>{`Comprehensive manual testing and WCAG Audits`}</h3>
    <p>{`Comprehensive manual accessibility testing and WCAG audits should be carried out by a specialist accessibility tester or consultant.`}</p>
    <p>{`If you are a Sainsbury's colleague and need help with manual testing, please contact a consultant using our get in touch section below.`}</p>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  We’re on hand to answer any questions you have or help you with your next
  project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      